<template>
  <app-layout>
    <div class="flex flex-col items-center py-4" v-loading.fullscreen.lock="loading">
        <div class="w-full px-12">
          <div class="flex flex-row items-center py-4">
            <sa-icon-button
                with-bg
                iconClass="bx bx-left-arrow-alt"
                @click="$router.push({ name: 'clients.index' })" />
            <h3 class="mx-2 text-lg font-bold">
              Ordenes de {{ client.fullName }}
            </h3>
            <div class="ml-auto">
              <sa-button
                :disabled="!$store.state.account.canCreateOrders"
                type="primary"
                class="items-center px-3 py-3 font-bold rounded"
                @click="$router.push({ name: 'orders.create', params: { clientId: client.id } })">
                Crear orden
              </sa-button>
            </div>
          </div>
          <div class="flex flex-col space-y-6">
            <content-card>
              <orders-grid
                :orders="orders"
                :referenceCanRedirectOrder="true"
                :canRedirectClientOrders="false"
                @onDeleteOrder="handleDeleteOrder"
                @onStatusUpdate="handleStatusUpdate"
                @onSorting="handleSorting"
              ></orders-grid>
            </content-card>
            <pagination :pagination="pagination" @onPaginationChange="onPaginationChange" />
          </div>
        </div>
      </div>
  </app-layout>
</template>
<script>
import OrderService from '@/services/OrderService';
import OrderPaymentDialogService, {
  command as OrderPaymentDialogCommand,
  commands as OrderPaymentDialogCommands,
} from '@/components/dialogs/OrderPayment/service';
import { getRestrictedStatuses } from '@/helpers/order-status.helper';

export default {
  name: 'OrdersClient',
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout'),
    ContentCard: () => import('@/components/molecules/ContentCard'),
    OrdersGrid: () => import('@/views/app/orders/grid/Index'),
    Pagination: () => import('@/components/molecules/Pagination'),
  },
  data() {
    return {
      loading: false,
      client: {
        id: null,
        fullName: null,
      },
      orders: [],
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalPages: 0,
      },
      sorting: {
        direction: 'desc',
      },
      subscription: null,
    }
  },
  created() {
    this.subscription = OrderPaymentDialogCommand.subscribe(this.handleOrderPaymentDialogCommands);
  },
  destroyed() {
    this.subscription.unsubscribe();
  },
  mounted() {
    this.client = this.$route.params;
    this.loadOrders();
  },
  methods: {
    onPaginationChange(pagination) {
      this.pagination =  { ...this.pagination, currentPage: pagination.currentPage };
      this.loadOrders();
    },
    async loadOrders() {
      this.loading = true;
      try {
        const { data, metadata } = await OrderService.getAll({
          clientId: this.client.id,
          pageNumber: this.pagination.currentPage,
          pageSize: this.pagination.perPage,
          sortDirection: this.sorting.direction,
        });
        this.pagination = {
          ...this.pagination,
          pageNumber: metadata.pageNumber,
          totalPages: metadata.pages,
        };
        this.orders = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } catch (error) {
        this.$toastr.e('Algo salió mal');
      } finally {
        this.loading = false;
      }
    },
    handleStatusUpdate({ orderId, status }) {
      this.loading = true;
      try {
        const { Finalized } = getRestrictedStatuses(this.$store.state.catalogs.orderStatuses);
        if (status === Finalized.id) {
          OrderPaymentDialogService.show({ orderId, status });
        } else {
          this.updateOrderStatus({ orderId, status });
        }
      } catch (e) {
        console.log('error', e);
      } finally {
        this.loading = false;
      }
    },
    handleOrderPaymentDialogCommands({ type, payload }) {
      switch (type) {
        case OrderPaymentDialogCommands.Select:
          this.updateOrderStatus(payload);
          break;
        default:
      }
    },
    async updateOrderStatus({ orderId, status, paymentMethod = undefined }) {
      await OrderService.updateStatus({ id: orderId, status, paymentMethod });
      this.$toastr.s('Se actualizo con exito');
      await this.loadOrders();
    },
    handleSorting(sorting) {
      const keys = Object.keys(sorting);
      this.sorting.direction = sorting[keys];
      this.loadOrders();
    },
    async handleDeleteOrder(orderId) {
      this.loading = true;
      try {
        await OrderService.deleteOrder(orderId);
        this.$toastr.s('Se borro con exito');
        this.loadOrders();
      } catch (e) {
        this.$toastr.e('Algo salió mal');
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
